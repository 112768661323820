@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .transition-ease-sm {
    @apply ease-in duration-75;
  }

  .transition-ease-md {
    @apply ease-in duration-150;
  }

  .bg-success {
    @apply bg-green-400 text-gray-50 transition-ease-md hover:bg-green-500;
  }

  .bg-warning {
    @apply bg-yellow-400 text-gray-900 transition-ease-md hover:bg-yellow-500;
  }

  .bg-error {
    @apply bg-red-500 text-gray-50 transition-ease-md hover:bg-red-600;
  }

  .bg-info {
    @apply bg-blue-400 text-gray-50 transition-ease-md hover:bg-blue-500;
  }

  .bg-dark {
    @apply bg-black text-gray-50 transition-ease-md hover:bg-gray-800;
  }

  .bg-light {
    @apply bg-white text-gray-900 border-2 border-gray-200 transition-ease-md hover:bg-gray-100;
  }

  .spacing-sm {
    @apply py-1 px-2 text-sm;
  }

  .spacing-md {
    @apply py-2 px-4;
  }

  .spacing-lg {
    @apply py-4 px-6;
  }

  .btn-icon {
    @apply flex items-center justify-center;
  }

  .form-input {
    @apply w-full py-2 px-2 border border-gray-300 focus:outline-none focus:border-brand-primary-500 rounded-md;
  }
}

*:focus {
  @apply outline-none;
}

ul.custom-list-type li {
  background: url(/assets/images/list-element.svg) no-repeat left top;
  padding: 3px 0px 3px 30px;
}

/** Givver DataSheet */
.dsg-cell.dsg-cell-header {
  @apply bg-white border-0;
  @apply text-black;
  @apply font-bold;
  @apply text-base;
}

.dsg.container,
.dsg.container .dsg-row.dsg-row-header,
.dsg.container .dsg-cell-header.dsg-cell-gutter,
.dsg-container .dsg-cell {
  box-shadow: none !important;
  border: 0 !important;
}
:root {
  --dsg-selection-border-color: #00e59d !important;
  --dsg-scroll-shadow-color: #00e59d !important;
  --dsg-scroll-shadow-width: 20px !important;
}
